import React from 'react'
import { Accordion } from 'react-bootstrap'

export default function EssentialInfo() {
  return (
    <div className='essential_info'>
      <div className="container">
        <h2 className='title'>ESSENTIAL INFORMATION</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Lorem ipsum, dolor sit amet consectetur adipisicing elit. </Accordion.Header>
            <Accordion.Body>
              <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Nobis ex facilis illum autem deserunt dolores suscipit quas sequi ipsum natus,</Accordion.Header>
            <Accordion.Body>
              <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
               
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>architecto ea culpa blanditiis quod deleniti excepturi</Accordion.Header>
            <Accordion.Body>
              <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>expedita eligendi necessitatibus?</Accordion.Header>
            <Accordion.Body>
            <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>ullamco laboris nisi ut
            aliquip ex ea commodo consequat.</Accordion.Header>
            <Accordion.Body>
            <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam</Accordion.Header>
            <Accordion.Body>
            <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header> Lorem ipsum dolor sit amet, consectetur adipiscing elit,</Accordion.Header>
            <Accordion.Body>
            <div className="collapse__content">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}
