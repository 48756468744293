import React from 'react'
import { Link } from 'react-router-dom'

export default function Contact() {
    return (
        <>
            <div className='skyward__contact'>
                <img src="assets/img/contact.jpg" className='w-100' alt="" />
            </div>
            <div className='skyward__contact_form'>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="contact_us">
                                <h2>Contact Us</h2>
                                <div className="Info">
                                    <div className="item">
                                        <div className="icon">
                                            <img src="assets/img/icons/email.svg" alt="" />
                                        </div>
                                        <h4>Email</h4>
                                        <p>Our friendly team is here to help.</p>
                                        <Link to=''>hello@skwardxperia.com</Link>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <img src="assets/img/icons/phone.svg" alt="" />
                                        </div>
                                        <h4>Phone</h4>
                                        <p>Mon-Fri from 8am to 5pm.</p>
                                        <Link to=''>+1 (555) 000-0000</Link>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <img src="assets/img/icons/location.svg" alt="" />
                                        </div>
                                        <h4>UAE</h4>
                                        <h5>Skyward Global LLC</h5>
                                        <h5>Sharjah Media City (Shams)</h5>
                                        <h5>PO BOX 515000</h5>
                                        <h5>Sharjah, UAE</h5>
                                    </div>
                                    <div className="item">
                                        <div className="icon">
                                            <img src="assets/img/icons/location.svg" alt="" />
                                        </div>
                                        <h4>USA</h4>
                                        <h5>7047 SW 47 ST Suite 0038</h5>
                                        <h5>Miami, FL 33155</h5>
                                        <h5>United States of America</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="getintouch">
                                <h2>Get in touch!</h2>
                                <h5>you can reach us anytime</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="1">First Name</label>
                                            <input type="text" placeholder='Enter Your Name' className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="1">Last Name</label>
                                            <input type="text" placeholder='Enter Your Name' className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="1">Email</label>
                                            <input type="email" placeholder='Enter Your Name' className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="1">Phone Number</label>
                                            <input type="text" placeholder='Enter Your Name' className='form-control' />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="1">Comment</label>
                                            <textarea placeholder='Enter Your Name' className='form-control' rows={4}></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p>You agree to our friendly privacy policy.</p>
                                    </div>
                                </div>
                                <div className="submit">
                                    submit <img src="/assets/img/icons/right.svg" height={30} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
