import React from 'react'
import Title from '../components/Title'

export default function About() {
    const featuresLists = [
        {
            img: "assets/img/icons/icon1.svg",
            title: "Reserve with Peace of Mind"
        },
        {
            img: "assets/img/icons/icon2.svg",
            title: "In-the-Know Insiders"
        },
        {
            img: "assets/img/icons/icon3.svg",
            title: "You're in excellent care."
        },
        {
            img: "assets/img/icons/icon4.svg",
            title: "Luxury Getaways"
        },
        {
            img: "assets/img/icons/icon5.svg",
            title: "Tailor-Made Tours"
        },
        {
            img: "assets/img/icons/icon6.svg",
            title: "Complete Financial Coverage"
        }
    ]
    return (
        <>
            <div className='about_page'>
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-md-6">
                            <div className="images">
                                <img src="assets/img/about2.jpg" alt="" />
                                <img src="assets/img/about1.jpg" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="content">
                                <h1>UNLEASH ICELAND'S MAGIC WITH SKYWARD XPERIA !</h1>
                                <p>Explore a land of dramatic contrasts - glacier lagoons reflecting icy peaks, volcanic wonders, and unique black sand beaches. Our dedicated travel consultants will craft the perfect itinerary for you, ensuring a personalized adventure from planning to unforgettable memories. immerse yourself in authentic Icelandic experiences.</p>
                            </div>
                            <div className="features">
                                {
                                    featuresLists?.map((ele, i) => {
                                        return (
                                            <div className="gridItems" key={i}>
                                                <img src={ele?.img} alt="" />
                                                <h4>{ele?.title}</h4>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="vision_mission">
                <div className="container-fluid pl-0 pe-0">
                    <div className="row mx-0">
                        <div className="col-md-6 p-0">
                            <div className="visionMission">
                                <div className="images">
                                    <img src="assets/img/vision.jpg" className='w-100' alt="" />
                                </div>
                                <div className="content">
                                    <img src="assets/img/icons/vision.svg" alt="" />
                                    <h3>VISION</h3>
                                    <p>Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra. Duis ut felis augue. Vestibulum eget iaculis neque, sit amet molestie ipsum. Donec faucibus ultricies nisi sit amet ullamcorper</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="visionMission">
                                <div className="images">
                                    <img src="assets/img/mission.jpg" className='w-100' alt="" />
                                </div>
                                <div className="content">
                                    <img src="assets/img/icons/mission.svg" alt="" />
                                    <h3>Mission</h3>
                                    <p>Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra. Duis ut felis augue. Vestibulum eget iaculis neque, sit amet molestie ipsum. Donec faucibus ultricies nisi sit amet ullamcorper</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="tailor_package">
                <div className="container">
                    <Title title={'TAILOR-MADE PACKAGES'} />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt corporis fugit ratione saepe ipsam quibusdam est earum laudantium aliquam ipsa. Facilis alias optio doloremque praesentium, blanditiis expedita quam laudantium laboriosam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi nam voluptas voluptatum eum. Animi iste porro magnam. Perferendis neque libero fugiat beatae minus corporis dignissimos quos laboriosam odio, excepturi dolorum. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem nulla eum modi itaque vitae! Repellendus molestias aut labore nostrum, consequuntur architecto deserunt iure ut totam doloribus culpa id laborum ex.
                    Ullam, quam sint ipsum nobis accusamus similique, labore fugiat repellat voluptas, tenetur laboriosam voluptate veniam inventore iusto? Aliquid fuga debitis excepturi voluptatum reprehenderit reiciendis atque, ducimus non ea hic! Similique.
                    Ratione modi fugiat fugit eveniet aspernatur repellendus repudiandae quaerat nesciunt iusto asperiores consequatur architecto iure ab, harum sequi aut explicabo molestiae sapiente quibusdam ullam animi temporibus. Molestiae facere sit quidem.
                    Magni molestias animi sequi eaque dolore corrupti culpa non autem! Ipsa culpa blanditiis accusantium beatae? Itaque excepturi totam est, dolorum hic rem animi amet. Quidem, at! Delectus esse iusto totam.</p>
                </div>
                <div className="container-fluid p-0">
                    <div className="row mx-0">
                        <img src="assets/img/about-package.jpg" alt="" />
                    </div>
                </div>
            </section>
        </>
    )
}
