import React from 'react'
import { vehicle_exp } from '../../components/config';
import Slider from 'react-slick';

export default function VehicleExperience() {
    const items = [
        {
            src: "/assets/img/vehicle/vehicle1.png",
            name: "Toyota prado",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle2.png",
            name: "Toyota RAV4",
            mandatory: true
        },
        {
            src: "/assets/img/vehicle/vehicle3.png",
            name: "Volkswagon Caravelle",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle4.png",
            name: "Mercedes GLE",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle5.png",
            name: "Land Rover Defender",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle6.png",
            name: "Land Cruiser",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle1.png",
            name: "Toyota prado",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle2.png",
            name: "Toyota RAV4",
            mandatory: true
        },
        {
            src: "/assets/img/vehicle/vehicle3.png",
            name: "Volkswagon Caravelle",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle4.png",
            name: "Mercedes GLE",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle5.png",
            name: "Land Rover Defender",
            mandatory: false
        },
        {
            src: "/assets/img/vehicle/vehicle6.png",
            name: "Land Cruiser",
            mandatory: false
        }
    ];
    return (
        <div className='vehicle_experience'>
            <div className="container">
                <div className="header">
                    <h2 className='title'>Vehicle Experience</h2>
                    <div className="partners">
                        Partners
                        <img src="/assets/img/partner1.png" alt="" />
                        <img src="/assets/img/partner2.png" alt="" />
                    </div>
                </div>
                <Slider {...vehicle_exp}>
                    {items.map((ele, index) => (
                        <div className='vehicle_details' key={index}>
                            <img src={ele?.src} alt="" />
                            <h2>{ele?.name}{ele?.mandatory &&<span>*</span>}</h2>
                        </div>
                    ))}
                </Slider>
                <div className="mandatory">
                    <p>Vehicle with <span>*</span> is subject to upgrade</p>
                </div>
            </div>
        </div>
    )
}
