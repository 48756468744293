import React from 'react'

export default function Activities() {
    const attractions = [
        {
            img: "/assets/img/attraction1.png",
            title: "Northern Lights Tour",
            desc: "Duration 4 hours"
        },
        {
            img: "/assets/img/attraction2.png",
            title: "Reykjavík City Walk",
            desc: "Duration 2 hours"
        },
        {
            img: "/assets/img/attraction3.png",
            title: "Northern Lights by Boat",
            desc: "Duration 2 hours"
        },
        {
            img: "/assets/img/attraction4.png",
            title: "Whale Watching",
            desc: "Duration 1 hours"
        }
    ]
  return (
    <div className='attraction'>
    <div className="container">
        <div className="header">
            <h2 className='title'>Activities</h2>
            <div className="view">
                View All
                <img src="/assets/img/icons/btn-arrow.svg" alt="" />
            </div>
        </div>
        <div className="row">
            {
                attractions?.map((ele, i) => {
                    return (
                        <div className="col-md-3" key={i}>
                            <div className="atraction">
                                <div className="image">
                                    <img src={ele?.img} className='w-100' alt="" />
                                </div>
                                <div className="contents">
                                    <h3>{ele?.title}</h3>
                                    <p><img src="/assets/img/icons/clock.svg" height={17} alt="" /> {ele?.desc}</p>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </div>
</div>
  )
}
