import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom'
export default function Footer() {
    return (
        <div className='skyward__footer'>
            <div className="container">
                <div className="footer_logo">
                    <img src="/assets/img/footer-logo.svg" alt="" />
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="footer__links">
                <div className="footer_widgets">
                    <h4>Skyward Global LLC</h4>
                    <ul>
                        <li><Link to={'/'}>Sharjah Media City (Shams)</Link></li>
                        <li><Link to={'/'}>PO BOX 515000</Link></li>
                        <li><Link to={'/'}>Sharjah, UAE</Link></li>
                    </ul>
                </div>
                <div className="footer_widgets">
                    <h4>Skyward Global LLC</h4>
                    <ul>
                        <li><Link to={'/'}>7047 SW 47 ST Suite 0038</Link></li>
                        <li><Link to={'/'}>Miami, FL 33155</Link></li>
                        <li><Link to={'/'}>United States of America</Link></li>
                    </ul>
                </div>
                <div className="quick_links">
                    <div className="navList">
                        <p><Link to={'/'}>Home</Link></p>
                        <p><Link to={'/about'}>About</Link></p>
                    </div>
                    <div className="navList">
                        <p> <Link to={'/self-drive'}>Self Drive</Link></p>
                        <p><Link to={'/curated'}>Curated Tours</Link></p>
                    </div>
                    <div className="navList">
                        <p><Link to={'/blogs'}>Blog</Link></p>
                        <p><Link to={'/contact'}>Contact</Link></p>
                    </div>
                </div>
                </div>
                
            </div>
            <div className="container">
            <div className="row justify-content-center">
                <div className="social__links">
                    <ul>
                        <li><Link to='/'><img src="/assets/img/icons/facebook.svg" alt="" /></Link></li>
                        <li><Link to='/'><img src="/assets/img/icons/x.svg" alt="" /></Link></li>
                        <li><Link to='/'><img src="/assets/img/icons/instagram.svg" alt="" /></Link></li>
                    </ul>
                </div>
                <div className="col-md-8">
                    <div className="privacy">
                        <ul>
                        <li><Link to='/'>Terms & Conditions</Link></li>
                        <li><Link to='/'>Privacy Policy</Link></li>
                        <li><Link to='/'>Cancellation Policy</Link></li>
                        <li><Link to='/'>© SMART HATCH TECHNOLOGIES FZE</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>

        </div>
    )
}
