import React from 'react'

export default function Blogs() {
    const blogs = [
        {
            src: "/assets/img/attraction1.png",
            title: "Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes",
        },
        {
            src: "/assets/img/attraction2.png",
            title: "Best 17-day northern lights self drive tour through south iceland to jokulsarlon ice cave",
        },
        {
            src: "/assets/img/blog3.jpg",
            title: "10-Day Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes",
        }
    ]
    return (
        <div className='blogs'>
            <div className="container">
                <h2 className='title'>related blogs</h2>
                <div className="row">
                    {
                        blogs?.map((ele, i) => {
                            return (
                                <div className="col-md-4" key={i}>
                                    <div className="blogGrid">
                                        <img src={ele?.src} className='w-100' alt="" />
                                        <h4>{ele?.title}</h4>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
