import React from 'react'
import { curated, curated_gallery } from '../components/config'
import Slider from 'react-slick'
import Title from '../components/Title'

export default function Curated() {
    return (
        <section className='curated__tours pt-0'>
            <div className="slider-container">
                <Slider {...curated}>
                    <div className='image_slide'>
                        <img src="assets/img/package2.jpg" alt="" />
                    </div>
                    <div className='image_slide'>
                        <img src="assets/img/package.jpg" alt="" />
                    </div>
                    <div className='image_slide'>
                        <img src="assets/img/package2.jpg" alt="" />
                    </div>
                </Slider>
            </div>
            <div className="curated_title">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            <Title title={'Curated'} />
                            <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eum sint, at expedita aut asperiores quidem facilis hic sapiente ab ex repellendus assumenda, quis maiores nemo, quae tempora placeat laboriosam aliquid.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="package_contents">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Tailor your tour package</h2>
                        </div>
                        <div className="col-md-6">
                            <p> Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores distinctio libero iste nam ducimus pariatur magni dolores hic. Delectus rem esse dolore temporibus maiores? Dolor dolorum culpa sit voluptas rerum! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet, blanditiis. Consequuntur doloremque, debitis nesciunt non deserunt cupiditate expedita cumque et tempore adipisci illo laudantium architecto! Corporis fugiat architecto alias vero?</p>
                        </div>
                        <div className="col-md-12 mt-5">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repudiandae, harum voluptatem? Animi, quod atque quisquam porro modi sunt consequuntur praesentium soluta ipsa perferendis ea Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aperiam quas soluta, dolore aliquid at voluptatibus aliquam, quae harum consequatur repellendus libero ab facere inventore quam. Commodi natus tempora laudantium nostrum! non. Doloribus laboriosam aut iure quod. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Illum, tenetur voluptatum, expedita saepe odio totam labore soluta delectus placeat tempore aut harum vero eius dolorem ea ab quam nesciunt optio!</p>
                        </div>
                    </div>
                </div>

                <div className="curated_gallery">
                    <Slider {...curated_gallery}>
                        <div>
                            <div className='row'>
                                <div className="col-6 video">
                                    <img src="assets/img/curated3.jpg" alt="" />
                                    <div className="play"><img src="assets/img/icons/play.svg" alt="" /></div>
                                </div>
                                <div className="col-6">
                                    <div className="image">
                                        <img src="assets/img/curated2.jpg" alt="" />
                                    </div>
                                    <div className="image">
                                        <img src="assets/img/curated1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='row'>
                                <div className="col-6 video">
                                    <img src="assets/img/package.jpg" alt="" />
                                    {/* <div className="play"><img src="assets/img/icons/insta.svg" alt="" /></div> */}
                                </div>
                                <div className="col-6">
                                    <div className="image">
                                        <img src="assets/img/vision.jpg" alt="" />
                                    </div>
                                    <div className="image">
                                        <img src="assets/img/mission.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="container">
                <div className="get_in_touch">
                    <Title title={'GET IN touch'} />
                    <div className="row gap-20">
                        <div className="col-md-6">
                            <img src="assets/img/getintouch.jpg" className='main w-100' alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="contents">
                                <div className="form-group">
                                    <label htmlFor="1">Name</label>
                                    <input type="text" placeholder='Enter Your Name' className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="1">Email</label>
                                    <input type="email" placeholder='Enter Your Email Address' className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="1">Phone</label>
                                    <input type="number" placeholder='Enter Your Phone Number' className='form-control' />
                                </div>
                                <div className="dates__guests">
                                <div className="form-group">
                                    <label htmlFor="1">Travel Dates</label>
                                    <input type="text" placeholder='Choose Date' className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="1">No of Guests</label>
                                    <input type="text" placeholder='Enter Your Name' className='form-control' />
                                </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="1">Point of Origin</label>
                                    <input type="text" placeholder='Select Department From' className='form-control' />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="1">Comment</label>
                                   <textarea className='form-control' rows={4} placeholder='Type Here'></textarea>
                                </div>
                                <div className="form-group right-align">
                                <button className='btn btn-primary'>SEND <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
