import React from 'react'

export default function Attractions() {
    const attractions = [
        {
            img: "/assets/img/attraction1.png",
            title: "Reykjavík",
            desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        },
        {
            img: "/assets/img/attraction2.png",
            title: "Gullfoss Waterfall",
            desc: "The 'Golden Falls', a powerful cascade surrounded by dramatic landscapes."
        },
        {
            img: "/assets/img/attraction3.png",
            title: "Blue Lagoon",
            desc: "Relax in a geothermal spa unlike any other, nestled in a mystical lava field."
        },
        {
            img: "/assets/img/attraction4.png",
            title: "Jökulsárlón Glacier Lagoon",
            desc: "Witness the awe-inspiring beauty of icebergs floating on a glacial lagoon."
        }
    ]
    return (
        <div className='attraction'>
            <div className="container">
                <div className="header">
                    <h2 className='title'>ATTRACTIONS</h2>
                    <div className="view">
                        View All
                        <img src="/assets/img/icons/btn-arrow.svg" alt="" />
                    </div>
                </div>
                <div className="row">
                    {
                        attractions?.map((ele, i) => {
                            return (
                                <div className="col-md-3" key={i}>
                                    <div className="atraction">
                                        <div className="image">
                                            <img src={ele?.img} className='w-100' alt="" />
                                        </div>
                                        <div className="contents">
                                            <h3>{ele?.title}</h3>
                                            <p>{ele?.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
