import React from 'react'
import { Form } from 'react-bootstrap'

export default function Overview() {
    return (
        <>
            <div className="section__overview">
                <div className="grid">
                    <p>From</p>
                    <h3>Dubai (DXB)</h3>
                </div>
                <div className="grid">
                    <p>To</p>
                    <h3>May 8th - May 13th, 2025</h3>
                </div>
                <div className="grid">
                    <p>Guests</p>
                    <h3>02 Passengers</h3>
                </div>
                <div className="grid">
                    <button className='btn btn-primary details' style={{ minWidth: "160px", justifyContent: "center" }}>MODIFY</button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 pe-0">
                    <div className="package_features">
                        <div className="lists">
                            <h3>5/5</h3>
                            <h6>rating</h6>
                        </div>
                        <div className="lists">
                            <ul>
                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                            </ul>
                            <h6>( 140 REVIEW )</h6>
                        </div>
                        <div className="lists">
                            <div className="icon"><img src="/assets/img/icons/drive.svg" alt="" /></div>
                            <h6>Self drive</h6>
                        </div>
                        <div className="lists">
                            <h3>08</h3>
                            <h6>Days</h6>
                        </div>
                        <div className="lists">
                            <div className="icon"><img src="/assets/img/icons/winter-tour.svg" alt="" /></div>
                            <h6>Winter</h6>
                        </div>
                        <div className="lists">
                            <div className="icon"><img src="/assets/img/icons/date-from.svg" alt="" /></div>
                            <h6>APR-OCT</h6>
                        </div>
                        <div className="lists">
                            <div className="icon"><img src="/assets/img/icons/km.svg" alt="" /></div>
                            <h6>2110/ 1144 km/mi</h6>
                        </div>
                    </div>
                    <div className="package_details">
                        <h2>OVERVIEW</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, </p>
                    </div>
                </div>
                <div className="col-md-6 ps-0">
                    <div className="package__pricing">
                        <div className="priceGrid">
                            <div className="item">
                                <p>Price per person/ per day</p>
                                <h6>From</h6>
                                <div className="price">
                                    <h3>2,500</h3>
                                    <Form.Select aria-label="currency">
                                        <option value="1">USD</option>
                                        <option value="2">AED</option>
                                        <option value="3">INR</option>
                                    </Form.Select>
                                </div>
                                <p>total of 5,000 USD</p>
                                <span>Exclusive of flight cost</span>
                            </div>
                            <div className="item">
                                <img src="/assets/img/icons/reserve.svg" alt="" />
                                <p>Reserve now for just $300 and pay the rest later!</p>
                            </div>
                        </div>
                        <div className="price_details">
                            <ul>
                                <li><img src="/assets/img/icons/hotel.svg" alt="" /> Premier Hotel Stays including Breakfasts</li>
                                <li><img src="/assets/img/icons/car-rent.svg" alt="" /> Car-Rentals </li>
                                <li><img src="/assets/img/icons/entertainment.svg" alt="" /> Entertainment activities including spa</li>
                                <li><img src="/assets/img/icons/insurance.svg" alt="" /> Car & Travel Insurance</li>
                                <li><img src="/assets/img/icons/customise.svg" alt="" /> Engagement session to customise travel experience.</li>
                                <li><img src="/assets/img/icons/support.svg" alt="" /> Live support during Travel</li>
                            </ul>
                        </div>
                        <div className="search__flight">
                            <h3>Ready To Book Your Dream Getaway? Click On 'Search Flights' To Begin!</h3>
                            <div className="explore">
                            <button className='btn btn-primary details'>EXPLORE <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                            <ul>
                                <li><img src="/assets/img/details1.jpg" alt="" /></li>
                                <li><img src="/assets/img/details1.jpg" alt="" /></li>
                                <li><img src="/assets/img/details1.jpg" alt="" /></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
