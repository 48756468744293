import React from 'react'
import { Accordion } from 'react-bootstrap'

export default function Itinerary() {
    return (
        <div className='itinerary'>
            <div className="container">
                <h2 className='title'>ITINERARY</h2>
                <img src="/assets/img/itinerary.png" className='w-100' alt="" />

              <div className="trip_schedules">
              <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Day 1 :Arrival</Accordion.Header>
                        <Accordion.Body>
                            <div className="collapse__content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                                <div className="gridLists">
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Attractions</p>
                                    </div>
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Activities</p>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Day 2- Vík - Skaftafell - Glacier Lagoon</Accordion.Header>
                        <Accordion.Body>
                        <div className="collapse__content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                                <div className="gridLists">
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Attractions</p>
                                    </div>
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Activities</p>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Day 3- East Fjords - Djúpivogur - Wilderness Center</Accordion.Header>
                        <Accordion.Body>
                        <div className="collapse__content">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                                <div className="gridLists">
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Attractions</p>
                                    </div>
                                    <div className="item">
                                        <img src="/assets/img/icons/entertainment.svg" alt="" />
                                        <p>Activities</p>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Day 4- Vík - Skaftafell - Glacier Lagoon</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Day 5- East Fjords - Djúpivogur - Wilderness Center</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>Day 6- East Fjords - Djúpivogur - Wilderness Center</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>Day 7- East Fjords - Djúpivogur - Wilderness Center</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>Day 8- East Fjords - Djúpivogur - Wilderness Center</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                            aliquip ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                            culpa qui officia deserunt mollit anim id est laborum.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
              </div>
            </div>
        </div>
    )
}
