import React, { useEffect, useState } from 'react'
import './Header.scss'
import { Link, NavLink, useLocation } from 'react-router-dom';

export default function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // MENU OPEN
  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen)
  }
  useEffect(() => {
    setMenuOpen(false)
  }, [location.pathname])

  // SEARCH
  const handleSearch=()=>{
    setSearchShow(!searchShow)
  }
  
  return (
    <>
      <div
        className={`main__header ${scrolled ? 'scrolled' : ''} ${location.pathname !== '/' || location.pathname !== '/curated' ? 'icon-change' : ''}`}
      >
        <div className="container">
          <div className="header">
            <div className="logo">
              <Link to='/'><img src="/assets/img/logo.svg" alt="" /></Link>
            </div>
            <div className="menu">
              <ul>
                <li><img src="/assets/img/icons/login.svg" alt="" /></li>
                <li onClick={handleSearch} className='pointer'><img src="/assets/img/icons/search.svg" alt="" /></li>
                <li onClick={handleMenuOpen} className='pointer'><img src="/assets/img/icons/menu.svg" alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={`sidemenu__wrapper ${menuOpen ? 'show' : ''}`}>
        <div className="sidemenu__content">
          <button className="closeButton sideMenuCls" onClick={handleMenuOpen}><img src="/assets/img/icons/close-menu.svg" alt="icon" /></button>
          <div className="widget footer_widget">
            <ul>
              <li><NavLink to='/'>Home</NavLink></li>
              <li><NavLink to='/about'>About</NavLink></li>
              <li><NavLink to='/self-drive'>Self Drive</NavLink></li>
              <li><NavLink to='/curated'>Curated Tours</NavLink></li>
              <li><NavLink to='/blogs'>Blogs</NavLink></li>
              <li><NavLink to='/contact'>Contact</NavLink></li>
            </ul>
          </div>
          <div className="social__links">
            <ul>
              <li><Link to='/'><img src="/assets/img/icons/facebook.svg" alt="" /></Link></li>
              <li><Link to='/'><img src="/assets/img/icons/x.svg" alt="" /></Link></li>
              <li><Link to='/'><img src="/assets/img/icons/instagram.svg" alt="" /></Link></li>
            </ul>
          </div>
        </div>
      </div>

      {/* SEARCH */}
      <div className={`sidemenu__wrapper search_wrapper ${searchShow ? 'show' : ''}`}>
        <div className="sidemenu__content search_bar">
          <button className="closeButton sideMenuCls" onClick={handleSearch}><img src="/assets/img/icons/close-menu.svg" alt="icon" /></button>
          <div className="widget footer_widget">
            <div className="search__area">
              <h2>Find What You're Looking For</h2>
              <p>Discover your dream Icelandic adventure with Skyward's tailored search—find the perfect tour package just for you.</p>
              <input type="text" className='form-control' placeholder='Search Here..' />
              <button className='btn btn-primary'>Search</button>
            </div>
          </div>
          <div className="social__links">
            <ul>
              <li><Link to='/'><img src="/assets/img/icons/facebook.svg" alt="" /></Link></li>
              <li><Link to='/'><img src="/assets/img/icons/x.svg" alt="" /></Link></li>
              <li><Link to='/'><img src="/assets/img/icons/instagram.svg" alt="" /></Link></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
