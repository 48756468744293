import React from 'react'

export default function StressFree() {
  return (
    <div className='stress__free'>
      <div className="container">
        <h4>OUR STRESS-FREE ICELAND ADVENTURE AWAITS</h4>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="features">
              <div className="gridItem">
                <img src="/assets/img/icons/stress_free1.svg" alt="" />
                <h5>Seamless Planning</h5>
                <p>Expert Guidance Rom Start To Finish.</p>
              </div>
              <div className="gridItem">
                <img src="/assets/img/icons/stress_free2.svg" alt="" />
                <h5>Effortless Travel</h5>
                <p>Handpicked Hotels With Breakfast And A Reliable Rental Car.</p>
              </div>
              <div className="gridItem">
                <img src="/assets/img/icons/stress_free3.svg" alt="" />
                <h5>Explore With Confidence</h5>
                <p>24/7 Support Throughout Your Trip</p>
              </div>
              <div className="gridItem">
                <img src="/assets/img/icons/stress_free4.svg" alt="" />
                <h5>Everything You Need, On The Go</h5>
                <p>All Your Documents Available In Our Mobile App.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="search_flight">
              <h4>Ready To Start Your Adventure?</h4>
              <p>Make A Token Payment, And We'll Handle The Rest!</p>
              <button className='btn btn-white' style={{maxWidth:"200px"}}>Search Flight <img src="/assets/img/icons/btn-arrow.svg" alt="" /></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
