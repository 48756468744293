export const choose_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  export const testimonial_settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  export const curated = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  export const curated_gallery = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  };
  
  export const recommended_tours = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  }

  export const vehicle_exp = {
    dots: false, // Shows navigation dots
    infinite: false, // Allows infinite scrolling
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    rows: 2, // Number of rows
    slidesPerRow: 3, // Number of slides per row (columns)
    arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  };

  export const partners_opt = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows : false
  };

  export const hotel_images = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5, 
    slidesToScroll: 1.5,
    swipeToSlide: true,
    arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  }

  export const rating_review = {
    dots: false,
    infinite: false, 
    speed: 500, 
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 3,
    arrows: false,
  }
  export const gallery = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  }