import React from 'react'

export default function IncludeExclude() {
    return (
        <>
            <div className='include__exclude'>
                <div className="container">
                    <h3>WHAT'S INCLUDED AND EXCLUDED</h3>
                </div>
            </div>
            <div className="include_exclude_items">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h4>Included</h4>
                            <ul>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> One private transfer from Keflavík Airport to Reykjavík</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Accommodation (Economy/Comfort Plus/Superior) for 9 nights Daily Breakfast</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Rental car of your choice with unlimited mileage, CDW and VAT for 8 days</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Free unlimited in-car Wi-Fi & 2 authorised drivers for the duration of the vehicle rental period</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> One semi-private transfer from Reykjavík to Keflavík Airport on departure</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Information meeting with your travel consultant (optional)</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Carbon offsetting of your tour</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> One Map of Iceland and detailed personal itinerary</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Nordic Visitor's Iceland Travel Guide</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> Driving in Iceland Pamphlet</li>
                                <li><img src="/assets/img/icons/check-white.svg" alt="" /> 24/7 helpline</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h4>Excluded</h4>
                            <ul>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Flights to/from Iceland</li>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Personal travel insurance</li>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Gasoline/Petrol</li>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Driving-Related Fees and expenses</li>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Meals & drinks (unless otherwise stated)</li>
                                <li><img src="/assets/img/icons/exclude-check.svg" alt="" /> Any services not listed above as “Included”</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
