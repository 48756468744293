import React, { useState } from 'react'
import HomeSearch from '../components/HomeSearch'
import Title from '../components/Title'
import { Button, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { choose_settings, testimonial_settings } from '../components/config'

export default function Home() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const featuresLists = [
    {
      img: "assets/img/icons/icon1.svg",
      title: "Reserve with Peace of Mind"
    },
    {
      img: "assets/img/icons/icon2.svg",
      title: "In-the-Know Insiders"
    },
    {
      img: "assets/img/icons/icon3.svg",
      title: "You're in excellent care."
    },
    {
      img: "assets/img/icons/icon4.svg",
      title: "Luxury Getaways"
    },
    {
      img: "assets/img/icons/icon5.svg",
      title: "Tailor-Made Tours"
    },
    {
      img: "assets/img/icons/icon6.svg",
      title: "Complete Financial Coverage"
    }
  ]

  const popular = [
    {
      img: "assets/img/package1.png",
      tag: "self drive",
      type: "all",
      title: "South Iceland In 8 Days",
      price: "10000",
    },
    {
      img: "assets/img/package2.png",
      tag: "self drive",
      type: "winter",
      title: "Ring Road Classic – Fast Paced In 14 Days",
      price: "10000",
    },
    {
      img: "assets/img/package3.png",
      tag: "self drive",
      type: "summer",
      title: "Full Icelandic Experience In 21 Days",
      price: "10000",
    },
    {
      img: "assets/img/package4.png",
      tag: "self drive",
      type: "winter",
      title: "Waterfalls & Wonders Of Iceland",
      price: "10000",
    }
  ]
  const attractions = [
    {
      img: "/assets/img/attraction1.png",
      title: "Reykjavík",
      desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
    },
    {
      img: "/assets/img/attraction2.png",
      title: "Gullfoss Waterfall",
      desc: "The 'Golden Falls', a powerful cascade surrounded by dramatic landscapes."
    },
    {
      img: "/assets/img/attraction3.png",
      title: "Blue Lagoon",
      desc: "Relax in a geothermal spa unlike any other, nestled in a mystical lava field."
    },
    {
      img: "/assets/img/attraction4.png",
      title: "Jökulsárlón Glacier Lagoon",
      desc: "Witness the awe-inspiring beauty of icebergs floating on a glacial lagoon."
    },
    {
      img: "/assets/img/attraction5.png",
      title: "Snæfellsjökull National Park",
      desc: "Explore otherworldly landscapes, glaciers, and volcanic wonders."
    },
    {
      img: "/assets/img/attraction6.png",
      title: "Golden Circle",
      desc: "A classic route encompassing Gullfoss, Geysir geothermal area, and Þingvellir National Park"
    },
    {
      img: "/assets/img/attraction7.png",
      title: "Northern Lights",
      desc: "Witness nature's light show, a dazzling display of colors dancing across the night sky (winter only)"
    },
    {
      img: "/assets/img/attraction8.png",
      title: "Black Sand Beaches",
      desc: "Witness the unique contrast of powerful waves crashing on jet-black volcanic sand."
    }
  ]

  const chooseUs = [
    {
      icon: "/assets/img/icons/icon1.svg",
      title: "Reserve with Peace of Mind",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon2.svg",
      title: "In-the-Know Insiders",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon3.svg",
      title: "You're in excellent care.",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon4.svg",
      title: "Luxury Getaways",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon1.svg",
      title: "Reserve with Peace of Mind",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon2.svg",
      title: "In-the-Know Insiders",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon3.svg",
      title: "You're in excellent care.",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    },
    {
      icon: "/assets/img/icons/icon4.svg",
      title: "Luxury Getaways",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies mi"
    }
  ]
  const testimonials = [
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      userImg: "assets/img/avatar1.png",
      name: "John Doe",
      designation: "Customer"
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      userImg: "assets/img/avatar2.png",
      name: "Alex",
      designation: "Customer"
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      userImg: "assets/img/avatar3.png",
      name: "Jane Doe",
      designation: "Customer"
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      userImg: "assets/img/avatar4.png",
      name: "Ahmad",
      designation: "Customer"
    },

  ]
  return (
    <>
      <Modal show={show} centered size='xl' backdrop='static' onHide={handleClose}>
        <Modal.Body>
          <div className="common__modal">
            <div className="row">
              <div className="col-md-5">
                <img src="/assets/img/modal.png" className='w-100' alt="" />
              </div>
              <div className="col-md-7">
                <div className="attractive_modal">
                  <div className="close" onClick={handleClose}><img src="assets/img/icons/modal-close.svg" alt="" /></div>
                  <h2>Northern Lights Tour</h2>
                  <div className="time"><img src="assets/img/icons/modal-date.svg" alt="" /> Duration 4 hours</div>
                  <h4>Details</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <section className='skyward__banner'>
        <video muted autoPlay playsInline loop className="masked-video" src={'/assets/video/video.mp4'} poster="assets/video/poster.png" />
        <div className="banner__title">
          <h1 className='stroke'>iceland</h1>
          <h1>iceland</h1>
        </div>
        <HomeSearch />
      </section>
      <section className="skyward__features">
        <div className="container">
          <div className="row row-cols-6">
            {
              featuresLists?.map((ele, i) => {
                return (
                  <div className="col pl-0" key={i}>
                    <div className={`featuresList ${i === 5 ? 'lastItem' : ''}`}>
                      <img src={ele?.img} alt="" />
                      <p>{ele?.title}</p>
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>
      </section>

      <section className="skyward__about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about_us">
                <img src="assets/img/about.png" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about_us">
                <h2>UNLEASH ICELAND'S MAGIC WITH SKYWARD XPERIA !</h2>
                <p>Explore a land of dramatic contrasts - glacier lagoons reflecting icy peaks, volcanic wonders, and unique black sand beaches. Our dedicated travel consultants will craft the perfect itinerary for you, ensuring a personalized adventure from planning to unforgettable memories. immerse yourself in authentic Icelandic experiences.</p>
                <button className='btn btn-primary'>EXPLORE <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__packages">
        <Title id={'testing'} title={'TAILOR-MADE PACKAGES'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="packageGrid">
                <img className='main' src="/assets/img/package.jpg" alt="" />
                <div className="content">
                  <h2>SELF-DRIVE TOURS</h2>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusantium sapiente incidunt qui enim commodi quam possimus iste, et cumque illum cum provident ut voluptates optio laudantium iure dicta dolorem ad? Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ducimus assumenda dolorem omnis culpa qui distinctio optio et consequatur odio, impedit nisi quisquam esse at provident vitae quia praesentium. Itaque, reprehenderit.</p>
                  <button className='btn btn-primary'>EXPLORE <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__popular_packages pt-0">
        <div className="container">
          <Title title={'POPULAR PACKAGES'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
          <div className="row">
            {
              popular?.map((ele, i) => {
                return (
                  <div className="col-md-3 p-2" key={i}>
                    <div className="package">
                      <div className="img">
                        <div className="type">
                          {
                            ele?.type === 'all' ? <img src='/assets/img/icons/all-year.svg' alt="All Year Icon" /> :
                              ele?.type === 'winter' ? <img src='/assets/img/icons/winter.svg' alt="Winter Icon" /> :
                                ele?.type === 'summer' ? <img src='/assets/img/icons/summer.svg' alt="Summer Icon" /> :
                                  null
                          }
                        </div>
                        <img src={ele?.img} alt="" />
                        <div className="tag">{ele?.tag}</div>
                      </div>
                      <div className="rating">
                        <ul>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                        </ul>
                        <span>5/5 rating</span>
                      </div>
                      <div className="days">08 days</div>
                      <h2>{ele?.title}</h2>
                      <div className="pricing">
                        <span>From</span>
                        <div className="price">
                          <h3>10000</h3>
                          <Form.Select aria-label="currency">
                            <option value="1">USD</option>
                            <option value="2">AED</option>
                            <option value="3">INR</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="view_trip">
                        <Link to='/'>view trip <img src="/assets/img/icons/btn-arrow.svg" alt="" /></Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>

          <div className="button_center">
            <button className='btn btn-primary'>VIEW ALL <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
          </div>
        </div>
      </section>
      <section className="skyward__attractions">
        <div className="container">
          <Title color={'#fff'} title={'ICELAND Attractions'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
          <div className="row mt-5">
            {
              attractions?.map((ele, i) => {
                return (
                  <div className="col-md-3" key={i} onClick={handleShow}>
                    <div className="atraction">
                      <div className="image">
                        <img src={ele?.img} alt="" />
                      </div>
                      <div className="contents">
                        <h3>{ele?.title}</h3>
                        <p>{ele?.desc}</p>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
      <section className="skyward__whyChoose_us">
        <Title title={'WHY CHOOSE US?'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
        <div className="row justify-content-end">
          <div className="col-md-10">
            <Slider {...choose_settings}>
              {
                chooseUs?.map((ele, i) => {
                  return (
                    <div className="choose_us">
                      <div className="icon">
                        <img src={ele?.icon} alt="why_choose_us" />
                        <h4>{ele?.title}</h4>
                      </div>
                      <p>{ele?.desc}</p>
                    </div>
                  )
                })
              }

            </Slider>
          </div>
        </div>
      </section>
      <section className="skyward__testimonials">
        <div className="container">
          <Title color={'#fff'} title={'HAPPY CUSTOMERS'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Slider {...testimonial_settings}>
                {
                  testimonials?.map((ele, i) => {
                    return (
                      <div className='testimonials_grid'>
                        <div className="testimonials">
                          <div className="testimonial_content">
                            <img src="assets/img/icons/quote.svg" alt="" />
                            <h4>{ele?.title}</h4>
                          </div>
                          <div className="author">
                            <ul>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                            </ul>
                            <div className="name">
                              <img src="assets/img/avatar1.png" alt="" />
                              <div className="content">
                                <h4>John Doe</h4>
                                <p>Customer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonials">
                          <div className="testimonial_content">
                            <img src="assets/img/icons/quote.svg" alt="" />
                            <h4>{ele?.title}</h4>
                          </div>
                          <div className="author">
                            <ul>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                            </ul>
                            <div className="name">
                              <img src="assets/img/avatar2.png" alt="" />
                              <div className="content">
                                <h4>John Doe</h4>
                                <p>Customer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonials">
                          <div className="testimonial_content">
                            <img src="assets/img/icons/quote.svg" alt="" />
                            <h4>{ele?.title}</h4>
                          </div>
                          <div className="author">
                            <ul>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                            </ul>
                            <div className="name">
                              <img src="assets/img/avatar3.png" alt="" />
                              <div className="content">
                                <h4>John Doe</h4>
                                <p>Customer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="testimonials">
                          <div className="testimonial_content">
                            <img src="assets/img/icons/quote.svg" alt="" />
                            <h4>{ele?.title}</h4>
                          </div>
                          <div className="author">
                            <ul>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                              <li><img src="assets/img/icons/rate.svg" alt="" /></li>
                            </ul>
                            <div className="name">
                              <img src="assets/img/avatar4.png" alt="" />
                              <div className="content">
                                <h4>John Doe</h4>
                                <p>Customer</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </Slider>
            </div>
          </div>

        </div>
      </section>
      <section className="skyward__curated">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
            <div className="packageGrid">
                <img className='main w-100' src="/assets/img/package2.jpg" alt="" />
              </div>
            </div>
            <div className="col-md-6">
            <div className="packageGrid">
                <div className="content">
                  <h2>CURATED</h2>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste tempore optio rem itaque, obcaecati, eum maiores illo, nemo numquam exercitationem dignissimos magnam officia culpa dolore et! Illum aspernatur perspiciatis fuga.
                  Recusandae reiciendis et incidunt eum expedita sed, error quidem aperiam deserunt praesentium nobis velit sunt repellat minus, tenetur provident obcaecati delectus earum. Hic ex dolorem ullam minima corporis beatae eveniet.</p>
                  <button className='btn btn-primary'>EXPLORE <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="skyward__blogs">
        <div className="container">
          <Title title={'our blogs'} desc='Phasellus nec finibus felis. Mauris pretium finibus lorem non viverra.' />
          <div className="row">
            <div className="col-md-6">
              <div className="blog">
                <img src="assets/img/blog3.jpg" alt="" />
                <h2>10-Day Self-Drive Tour of the Complete Ring Road of Iceland with Top Attractions & Snaefellsnes</h2>
              </div>
            </div>
            <div className="col-md-6">
              <div className="blog sm-style">
                <img src="assets/img/package3.png" alt="" />
                <h2>Best 1-week Summer Self-Drive Tour of the Ring Road of Iceland & Golden Circle</h2>
              </div>
              <div className="blog sm-style">
                <img src="assets/img/package4.png" alt="" />
                <h2>Best 7-Day Northern Lights Self Drive Tour Through South Iceland to Jokulsarlon Ice Cave</h2>
              </div>
            </div>
          </div>
          <div className="button_center">
            <button className='btn btn-primary'>VIEW ALL <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
          </div>
        </div>
      </section>
    </>
  )
}
