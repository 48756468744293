import React from 'react'
import { Link } from 'react-router-dom'
import Title from '../components/Title'

export default function Blogs() {
    const blogs = [
        {
            img: "assets/img/blog1.png",
            title: "Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incidunt iure architecto quam,",
        },
        {
            img: "assets/img/blog2.png",
            title: "Best 17-day northern lights self drive tour through south iceland to jokulsarlon ice cave",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incidunt iure architecto quam, aut commodi nostrum illo blanditiis suscipit expedita, inventore itaque id facilis doloribus.",
        },
        {
            img: "assets/img/blog3.png",
            title: "Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incidunt iure architecto quam, aut commodi nopedita, inventore itaque id facilis doloribus.",
        },
        {
            img: "assets/img/blog4.png",
            title: "Best 17-day northern lights self drive tour through south iceland to jokulsarlon ice cave",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incidunt iure lis doloribus.",
        },
        {
            img: "assets/img/blog5.png",
            title: "Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incim, aut commodi nostrum illo blanditiis suscipit expedita, inventore itaque id facilis doloribus.",
        },
        {
            img: "assets/img/blog6.png",
            title: "Best 17-day northern lights self drive tour through south iceland to jokulsarlon ice cave",
            desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit qui rem, porro mollitia quaerat incidunt iureipit expedita, inventore itaque id facilis doloribus.",
        }
    ]
    return (
        <>
            <section className='skyward__featured_blogs pt-0'>
                <img src="assets/img/featured-blog.jpg" className='w-100' alt="" />
                <div className="container">
                    <div className="featured">
                        <h1>Glacier Lagoons Reflecting Icy Peaks, Volcanic Wonders, And Unique Black Sand Beaches.</h1>
                        <div className="date">
                            <p>02 Auguest 2024</p>
                            <Link to='/'>Read More</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="latest__blogs">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                            <Title title={'Latest Blogs'} desc={' Lorem ipsum dolor sit amet consectetur adipisicing elit. Illo architecto sint libero totam minus reprehenderit voluptas ipsum dolores dolorem, eos unde, harum quos nostrum expedita dolorum rerum vero dignissimos hic?'} />
                        </div>
                    </div>
                    <div className="row mt-4">
                        {
                            blogs?.map((ele, i) => {
                                return (
                                    <div className="col-md-6" key={i}>
                                        <Link to={`/blogs-details/${i}`}>
                                            <div className="blog__lists">
                                                <div className="image"> <img src={ele?.img} className='w-100' alt="" /></div>
                                                <div className="share">
                                                    <p>02 Auguest 2024</p>
                                                    <div className="socials">
                                                        <p>Share</p>
                                                        <ul>
                                                            <li><img src="assets/img/icons/fb-share.svg" alt="" /></li>
                                                            <li><img src="assets/img/icons/x-share.svg" alt="" /></li>
                                                            <li><img src="assets/img/icons/instagram-share.svg" alt="" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <h2>{ele?.title}</h2>
                                                <p>{ele?.desc}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
