import React from 'react'
import Slider from 'react-slick'
import { curated } from '../../components/config'
import { Link as ScrollLink, Element } from 'react-scroll';
import Overview from './Overview';
import Highlights from './Highlights';
import Itinerary from './Itinerary';
import Attractions from './Attractions';
import Activities from './Activities';
import VehicleExperience from './VehicleExperience';
import HotelExperience from './HotelExperience';
import IncludeExclude from './IncludeExclude';
import Blogs from './Blogs';
import EssentialInfo from './EssentialInfo';
import StressFree from '../../components/StressFree';

export default function PackagesDetails() {
    return (
        <section className='skyward__package_details p-0'>
            <Slider {...curated}>
                <div className='image_slide'>
                    <img src="/assets/img/details1.jpg" className='w-100 main' alt="" />
                    <div className="container">
                        <div className="col-7">
                            <div className="content">
                                <h1>
                                    RING ROAD CLASSIC –FAST PACED IN 08 DAYS
                                </h1>
                                <div className="btns">
                                    <button className='btn btn-secondary details'>View on map <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                                    <button className='btn btn-secondary details'>View Gallery <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='image_slide'>
                    <img src="/assets/img/details2.jpg" className='w-100 main' alt="" />
                </div>
                <div className='image_slide'>
                    <img src="/assets/img/details3.jpg" className='w-100 main' alt="" />
                </div>
            </Slider>

            <div className="package__features">
                <div className="gridFeatures">
                    <ul>
                        <li> <ScrollLink activeClass="active" to="overview" spy={true} smooth={true} offset={-140} duration={500} > OVERVIEW </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="highlight" spy={true} smooth={true} offset={-140} duration={500} > HIGHLIGHTS </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="itinerary" spy={true} smooth={true} offset={-140} duration={500} > ITINERARY </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="attraction" spy={true} smooth={true} offset={-140} duration={500} > ATTRACTIONS </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="activities" spy={true} smooth={true} offset={-140} duration={500} > ACTIVITIES  </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="vehicle" spy={true} smooth={true} offset={-140} duration={500} > VEHICLE EXPERIENCE  </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="hotel" spy={true} smooth={true} offset={-140} duration={500} > HOTEL EXPERIENCE  </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="included" spy={true} smooth={true} offset={-140} duration={500} > INCLUDED AND EXCLUDED  </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="blogs" spy={true} smooth={true} offset={-140} duration={500} > BLOGS </ScrollLink> </li>
                        <li> <ScrollLink activeClass="active" to="info" spy={true} smooth={true} offset={-170} duration={500} > ESENTIAL INFO </ScrollLink> </li>
                    </ul>
                </div>

                <Element name="overview" className="section"> <div className="container"> <Overview /> </div> </Element>
                <Element name="highlight" className="section"> <Highlights /> </Element>
                <Element name="itinerary" className="section"> <Itinerary /> </Element>
                <Element name="attraction" className="section"> <Attractions /> </Element>
                <Element name="activities" className="section"> <Activities /> </Element>
                <Element name="vehicle" className="section"> <VehicleExperience /> </Element>
                <Element name="hotel" className="section"> <HotelExperience /> </Element>
                <Element name="included" className="section"> <IncludeExclude /> </Element>
                <Element name="blogs" className="section"> <Blogs /> </Element>
                <Element name="info" className="section"> <EssentialInfo /> </Element>
            </div>
            <StressFree/>

        </section>
    )
}
