import React from 'react'

export default function Highlights() {

    const highlight = [
        {
            title: "Jökulsárlón Glacier Lagoon",
            desc: "Witness massive icebergs and glistening ice chunks.",
        },
        {
            title:"Lake Mývatn",
            desc:"Discover volcanic landscapes and unique geological formations."
        },
        {
            title:"Golden Circle",
            desc:"Explore Þingvellir National Park, Gullfoss waterfall, and Geysir geothermal area."
        },
        {
            title:"Akureyri",
            desc:"Stroll through the charming 'Capital of the North'."
        },
        {
            title:"Northern Lights",
            desc:"Hunt for the mesmerizing aurora borealis."
        },
        {
            title:"Seljalandsfoss",
            desc:"Admire two of Iceland's most beautiful waterfalls."
        },
        {
            title:"Northern Lights",
            desc:"Hunt for the mesmerizing aurora borealis."
        },
        {
            title:"Seljalandsfoss",
            desc:"Admire two of Iceland's most beautiful waterfalls."
        }
    ]
    return (
        <div className='highlights'>
            <div className="container">
                <div className="header">
                    <h2 className='title'>highlight</h2>
                    <div className="view">
                        View All
                        <img src="/assets/img/icons/btn-arrow.svg" alt="" />
                    </div>
                </div>
                <div className="row">
                    {
                        highlight?.map((ele, i) => {
                            return (
                                <div className="col-md-6" key={i}>
                                    <div className="highlight_grid">
                                        <h4>{ele?.title}</h4>
                                        <p>{ele?.desc}</p>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}
